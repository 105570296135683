// import { HopeProvider } from '@hope-ui/solid';
import Main from './Main';

function App() {
  // return (
  //   <HopeProvider>
  //     <Main />
  //   </HopeProvider>
  // )
  return <Main />
}

export default App;
